import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Axios from 'axios';
import Viewer from './Viewer';
import PatientCookieOptIn from './published/PatientCookieOptIn';
import NotFound from './pages/404';
import Forbidden from './Forbidden';
import OptedIn from './pages/OptedIn';
import Loading from './pages/qoos/Loading';

// Developers
import DeveloperDashboard from './pages/developer/DeveloperDashboard';
import DeveloperScan from './pages/developer/DeveloperScan';
import DeveloperCookie from './pages/developer/DeveloperCookie';

export const ViewerContext = createContext(null);

const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_URL
    : process.env.REACT_APP_DEV_URL;

const App = () => {
  const [loading, setLoading] = useState(true)
  const [cookies] = useCookies('patient_token');
  const [optInFinished, setOptInFinished] = useState(false);
  const [alreadyOptedIn, setAlreadyOptedIn] = useState(false);
  const [wrongPatientToken, setWrongPatientToken] = useState(true);
  const [studyName, setStudyName] = useState();
  const patientToken = window?.location?.pathname?.split('/')[2] || null;

  // Fonts
  const [fonts, setFonts] = useState([]);
  const [fontsReady, setFontsReady] = useState(false);

  // ----------- PWA functionality --------------------- MANUAL, NO WORKBOX AND SW
  const [statusOnline, setStatusOnline] = useState(navigator.onLine);

  const ejectQueue = async () => {
    const pwa = JSON.parse(localStorage.getItem('pwa') || '[]');

    if (pwa.length > 0) {
      pwa.forEach(async (q, i, o) => {
        try {
          const res = await Axios.post(q.url, q.data, {});
          o.splice(i, 1);
          localStorage.setItem('pwa', JSON.stringify(o));
        } catch (error) {
          console.log(error.message);
        }
      });
    }
  };

  useEffect(() => {
    const setI = setInterval(() => {
      ejectQueue();
    }, 2000);

    return () => clearInterval(setI);
  }, []);

  /**
   *
   * @param {String} url
   * @param {Object} data
   * @param {String} method
   */
  const pwaAppend = (url, data, method = 'POST') => {
    // Get PWA urls array from localStorage
    let pwa = JSON.parse(localStorage.getItem('pwa') || '[]');

    // Check if request already exists in array and replace it with the last by event/qoo/qoolection
    const found = pwa.findIndex(p => {
      if (p.url === url) {
        if (p.data.eventType === data.eventType) {
          return true;
        }
      }

      return false;
    })

    // If record found in localStorage, remove it and after replace with new one (push a new request)
    if (found >= 0) {
      pwa.splice(found, 1);
    }

    pwa.push({ url, data, method });
    localStorage.setItem('pwa', JSON.stringify(pwa));
  };

  const getOnline = () => {
    setStatusOnline(true);
    ejectQueue();
  };

  const getOffline = () => {
    setStatusOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', getOnline);
    window.addEventListener('offline', getOffline);
  }, []);
  // ----------- End PWA functionality -------------------


  // ----------- Start FONTS fetch -----------------------
  const addStylesheetRules = (rules) => {
    const styleEl = document.createElement("style");

    // Append <style> element to <head>
    document.head.appendChild(styleEl);

    // Grab style element's sheet
    const styleSheet = styleEl.sheet;

    for (let i = 0; i < rules.length; i++) {

      // Insert CSS Rule
      styleSheet.insertRule(
        rules[i],
        styleSheet.cssRules.length
      );
    }
  }

  useEffect(() => {
    addStylesheetRules(fonts)
  }, [fontsReady])

  const fetchFonts = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/fonts`);
      if (res.status === 200 && res.data.length > 0) {
        const allFonts = res.data;
        const availableFonts = allFonts.map((font) => {
          const newFont = {
            _id: font._id,
            family: font.fontFamily,
            name: font.name,
            style: font.fontType.toLowerCase().includes('italic')
              ? 'italic'
              : 'normal',
            weight: font.fontType.toLowerCase().includes('light')
              ? 300
              : font.fontType.toLowerCase().includes('semibold')
                ? 600
                : font.fontType.toLowerCase().includes('bold')
                  ? 700
                  : font.fontType.toLowerCase().includes('black')
                    ? 900
                    : 400,
            importUrl: font.location,
          };

          return `@font-face { font-family: ${newFont.name}; src: url(${font.location}); font-weight: ${newFont.weight}; font-style: ${newFont.style}; }`;
        });

        setFonts([...availableFonts]);
        setFontsReady(true);
      }
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
          ? error.message
          : error;
      console.log(msg);
    }
  };
  useEffect(() => {
    fetchFonts();
  }, []);
  // ----------- End FONTS fetch -------------------------

  // ----------- Start PATIENT TOKEN CHECK ---------------
  useEffect(() => {
    if (patientToken) {
      checkForPatientCookie();
    } else {
      setWrongPatientToken(true);
      setLoading(false);
    }
  }, [patientToken, cookies]);

  const checkForPatientCookie = async () => {
    try {
      const check = await Axios.get(
        `${SERVER_URL}/patients-check-token/${patientToken}`,
        { withCredentials: false }
      );

      const found = await check?.data?.found;
      const optedIn = await check?.data?.optedIn;
      if (found) {
        // patientToken found
        setWrongPatientToken(false);
        setStudyName(check?.data?.study)
        setAlreadyOptedIn(optedIn);
      } else {
        // Wrong patientToken -> OptIn or 404
        setWrongPatientToken(true);
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };
  // ----------- End PATIENT TOKEN CHECK -----------------

  // ----------- Start OPT-IN CHECK ----------------------
  useEffect(() => {
    if (patientToken === cookies?.patient_token) {
      setOptInFinished(true);
    }
  }, [patientToken, cookies])
  // ----------- End OPT-IN CHECK ------------------------

  /** MANIFEST */
  const manifestJSON = {
    "short_name": "QClinical",
    "name": document.title || "QClinical",
    "icons": [
      {
        "src": `${SERVER_URL}/favicon.ico`,
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      },
      {
        "src": `${SERVER_URL}/logo192.png`,
        "type": "image/png",
        "sizes": "192x192"
      },
      {
        "src": `${SERVER_URL}/logo512.png`,
        "type": "image/png",
        "sizes": "512x512"
      }
    ],
    "start_url": `${SERVER_URL}/view/${patientToken ? patientToken : ""}`,
    "display": "standalone",
    "theme_color": "#000000",
    "background_color": "#ffffff"
  }

  const stringManifest = JSON.stringify(manifestJSON);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

  var link = document.createElement('Link');
  link.rel = "manifest";
  link.setAttribute('href', 'data:application/json;charset=8' + stringManifest)

  /** END MANIFEST */

  if (loading) {
    return <Loading isLoading={loading} />
  }

  return (
    <ViewerContext.Provider value={{ statusOnline, pwaAppend, studyName }}>
      <Router>
        <Routes>
          {wrongPatientToken &&
            <Route
              path='/*'
              element={
                <div className='opt-in__body' style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                  <NotFound />
                </div>
              }
            />
          }

          {!wrongPatientToken && (
            <Route
              path='/view/:patientToken'
              element={!optInFinished ? (
                !alreadyOptedIn ?
                  <PatientCookieOptIn
                    server={SERVER_URL}
                    patientToken={patientToken}
                  /> :
                  <div className='opt-in__body' style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                    <OptedIn />
                  </div>
              ) :
                <Viewer
                  server={SERVER_URL}
                  patientToken={patientToken}
                />
              }
            />
          )}

          <Route path='/developer' element={<DeveloperDashboard />} />
          <Route path='/developer/scan' element={<DeveloperScan />} />
          <Route path='/developer/cookie' element={<DeveloperCookie />} />
          <Route path='/forbidden' element={<Forbidden />} />

        </Routes>
      </Router>
    </ViewerContext.Provider>
  );
};

export default App;
